.rows{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
/* */
.col-md-4s {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box;
  }

body{
    /* background-color: #F6F8FC !important; */
    background-color:white;
    font-family:  Calibri;
}
.InstructorBg{
    background-color: #A9CCE3  !important;
    border-radius: 8px;
}
.EmailInput{
    background-color: #EAF2F8  !important;
    border-radius: 8px;
    font-size: 1.25rem;
    padding-inline-start:25px;
}
.EmailInput:focus {
    border: 2px solid #4183d9;
    outline: none;
}
.PaswordInput{
    background-color: #EAF2F8  !important;
    border-radius: 8px;
    font-size: 1.25rem;
    padding-inline-start:25px;
}
.PaswordInput:focus{
    border: 2px solid #4183d9;
    outline: none;
}

.GameOn{
    background-color: #FADBD8  !important;
    border-radius: 8px;
    margin-bottom: 5px;
}

.SignIn{
    width: 360px;
}

