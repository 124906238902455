
.SlectTabColor{
    background-color: #7688A1;
    color: white;
}
.BgcolorOf4{
    background-color: #DBE6F5;
}
.SidbarBgColor{
    background-color:#E8EFF9 !important;
}
.textcolor{
    color: #333333 !important;
}

/* Content side */
.ContentHeadingBg{
    background-color: #6788A1;
    color: white;
    padding: 11px 20px;
}
.ContentAreaBg{
    background-color: white;
    height: 89%;
}
.FixedWidth256{
    width: 256px;
}